import StopIcon from "@assets/icons/StopIcon";
import Copied from "@assets/icons/copied.svg";
import Copy from "@assets/icons/copy.svg";
import { CommonInput } from "@commonComponents/input";
import { useStore } from "@stores/root-store";
import { validateMessages } from "@utils/json-data";
import { Button, Form, Select } from "antd";
import classNames from "classnames";
import { FC, memo, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import style from "./style.module.scss";
import SpinnerGeneration from "../spinner-generating";
import ReportModal from "@components/modal/report-modal";
import removeMd from "remove-markdown";
import down from "@assets/images/down.svg";

interface NoObjectionProcedureFormProps {
  Text: JSX.Element;
  disabled: boolean;
  maxLength: number;
  handleSubmit: (saveOnly: boolean) => void;
  form: any;
  initialState: any;
  placeholder?: string;
  isFormPage?: boolean;
  isSubmitting?: boolean;
  value?: string;
  isItalicFontStyle?: boolean;
  section?: string;
  isValid?: boolean;
  setIsValid?: (data: boolean) => void;
  handleStopGenerate: () => void;
  handleViewReport: () => void;
  sectionOutputPresent: boolean;
  isLoading: boolean;
  isAnswer?: boolean;
}

const NoObjectionProcedureForm: FC<NoObjectionProcedureFormProps> = ({
  Text,
  disabled,
  maxLength,
  handleSubmit,
  form,
  initialState,
  placeholder,
  isFormPage,
  value,
  isSubmitting,
  isItalicFontStyle,
  section,
  isValid,
  setIsValid,
  handleStopGenerate,
  handleViewReport,
  sectionOutputPresent,
  isLoading,
  isAnswer,
}) => {
  const {
    user: { handleCheckInputTokensValid },
  } = useStore(null);
  const [copied, setCopied] = useState(false);

  const { pathname } = useLocation();
  const type = pathname.split("/")[3];
  const step = pathname.split("/")[4];

  const checkLengthOfInput = async (value: string) => {
    const data = {
      functionality: "no objection procedure",
      section: section,
      input: value,
    };

    await handleCheckInputTokensValid(data).then((res) => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    if (!copied) return;

    setTimeout(() => {
      setCopied(null);
    }, 2000);
  }, [copied]);

  return (
    <>
      <div className={style.formWrapper}>
        <Form
          className={style.form}
          form={form}
          autoComplete="false"
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          layout="vertical"
          initialValues={{
            q: initialState?.input,
          }}
        >
          {/*{+step === 3 && type !== "results" ? (*/}
          {/*  <>*/}
          {/*    <span*/}
          {/*      className={*/}
          {/*        isLoading === false && sectionOutputPresent*/}
          {/*          ? style.viewOutputButton*/}
          {/*          : style.viewOutputButtonDisabled*/}
          {/*      }*/}
          {/*      style={*/}
          {/*        +step === 3 ? { display: "flex", justifyContent: "end" } : {}*/}
          {/*      }*/}
          {/*      onClick={() =>*/}
          {/*        sectionOutputPresent ? handleViewReport() : null*/}
          {/*      }*/}
          {/*    >*/}
          {/*      View Last Output*/}
          {/*    </span>*/}
          {/*    <div className={style.dataContentBox}>*/}
          {/*      <div className={style.questionOne}>*/}
          {/*        <Form.Item*/}
          {/*          label={*/}
          {/*            <span style={{ marginBottom: "8px" }}>*/}
          {/*              {"Sector to be addressed"}*/}
          {/*              <span style={{ color: "red" }}>*</span>*/}
          {/*            </span>*/}
          {/*          }*/}
          {/*          name={"q1a"}*/}
          {/*          rules={[*/}
          {/*            { required: true, message: "This field is required" },*/}
          {/*          ]}*/}
          {/*        >*/}
          {/*          <Select*/}
          {/*            key={"sector"}*/}
          {/*            placeholder="Select sector"*/}
          {/*            className="height-50 ant-select-selector ant-select-selection-placeholder"*/}
          {/*            disabled={isLoading}*/}
          {/*            style={{*/}
          {/*              width: "318px",*/}
          {/*              height: "50px !important",*/}
          {/*              textAlign: "start",*/}
          {/*              padding: "0",*/}
          {/*            }}*/}
          {/*            suffixIcon={*/}
          {/*              <img*/}
          {/*                style={{ marginBottom: "6px" }}*/}
          {/*                src={down}*/}
          {/*                alt={"down icon"}*/}
          {/*              />*/}
          {/*            }*/}
          {/*            options={[*/}
          {/*              {*/}
          {/*                value:*/}
          {/*                  "Buildings, cities, industries, and appliances",*/}
          {/*                label:*/}
          {/*                  "Buildings, cities, industries, and appliances",*/}
          {/*              },*/}
          {/*              {*/}
          {/*                value: "Ecosystems and ecosystem services",*/}
          {/*                label: "Ecosystems and ecosystem services",*/}
          {/*              },*/}
          {/*              {*/}
          {/*                value: "Energy generation and access",*/}
          {/*                label: "Energy generation and access",*/}
          {/*              },*/}
          {/*              {*/}
          {/*                value: "Forests and land use",*/}
          {/*                label: "Forests and land use",*/}
          {/*              },*/}
          {/*              {*/}
          {/*                value: "Health, food, and water security",*/}
          {/*                label: "Health, food, and water security",*/}
          {/*              },*/}
          {/*              {*/}
          {/*                value: "Infrastructure and built environment",*/}
          {/*                label: "Infrastructure and built environment",*/}
          {/*              },*/}
          {/*              {*/}
          {/*                value: "Livelihoods of people and communities",*/}
          {/*                label: "Livelihoods of people and communities",*/}
          {/*              },*/}
          {/*              {*/}
          {/*                value: "Transport",*/}
          {/*                label: "Transport",*/}
          {/*              },*/}
          {/*              {*/}
          {/*                value: "Multi-sector",*/}
          {/*                label: "Multi-sector",*/}
          {/*              },*/}
          {/*            ]}*/}
          {/*          />*/}
          {/*        </Form.Item>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </>*/}
          {/*) : null}*/}

          <div className={style.labelContainer}>
            <span className={style.label}>{Text}</span>
            {type === "results" ? null : (
              <span
                className={
                  isLoading === false && sectionOutputPresent
                    ? style.viewOutputButton
                    : style.viewOutputButtonDisabled
                }
                onClick={() =>
                  sectionOutputPresent ? handleViewReport() : null
                }
              >
                View Last Output
              </span>
            )}
          </div>
          <div className={style.textareaWrapper}>
            <Form.Item
              name={"q"}
              rules={[
                { required: !disabled, message: "This field is required" },
              ]}
            >
              {isSubmitting && !isAnswer ? (
                <SpinnerGeneration />
              ) : (
                <CommonInput
                  placeholder={
                    isSubmitting
                      ? "If you don’t see any output below within 7 seconds, you may have exceeded the permitted word limit in your input. Please reduce your input - thank you!"
                      : placeholder || ""
                  }
                  value={isFormPage ? initialState?.input : ""}
                  defaultValue={!isFormPage && !isSubmitting ? value : ""}
                  disabled={disabled}
                  size={3}
                  // TODO: removed input limit until further notice
                  // maxlength={maxLength * 10}
                  // onChange={(e) => {
                  //   checkLengthOfInput(e.target.value);
                  // }}
                  inputType="textarea"
                  style={{ cursor: isLoading ? "wait" : "", minHeight: "55vh" }}
                  autoSizeCheck={{
                    minRows: isFormPage ? 19 : 2,
                    maxRows: 1111,
                  }}
                  className={classNames(style.textarea)}
                  isMarkdown={!isFormPage}
                />
              )}
            </Form.Item>
            {!isFormPage &&
              form.getFieldValue("q") &&
              (copied ? (
                <img src={Copied} alt="copy" />
              ) : (
                <img
                  src={Copy}
                  alt="copy"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      removeMd(form.getFieldValue("q")),
                    );
                    setCopied(true);
                  }}
                />
              ))}
          </div>
        </Form>
        {!isValid && (
          <div style={{ color: "red", fontSize: "14px" }}>
            You have exceeded the maximum length for this field
          </div>
        )}
        {/*<div style={{ paddingTop: "5px" }}>*/}
        {/*  The max word limit for this page is 1000 words*/}
        {/*</div>*/}
        {!isFormPage && (
          <div
            style={{
              border: "1px solid #E0E0E0",
              position: "relative",
              top: "-16px",
              margin: "1px",
              padding: "10px 17px",
              display: "flex",
              justifyContent: "end",
              borderRadius: "0 0 5px 5px",
            }}
          >
            <div
              style={{
                height: "fit-content",
                cursor: !isSubmitting ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (isSubmitting) {
                  handleStopGenerate();
                }
              }}
            >
              <StopIcon color={!isSubmitting ? "#dddddd" : ""} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(NoObjectionProcedureForm);
