import StopIcon from "@assets/icons/StopIcon";
import Copied from "@assets/icons/copied.svg";
import Copy from "@assets/icons/copy.svg";
import { CommonInput } from "@commonComponents/input";
import { useStore } from "@stores/root-store";
import { validateMessages } from "@utils/json-data";
import { Form } from "antd";
import classNames from "classnames";
import React, { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DraftFirstStep } from "./first-step/DraftFirstStep";
import style from "./styles.module.scss";
import SpinnerGeneration from "../spinner-generating";
import removeMd from "remove-markdown";

interface DraftFormProps {
  Text: JSX.Element;
  disabled: boolean;
  maxLength: number;
  handleSubmit: (saveOnly: boolean) => void;
  form: any;
  firstStepForm: any;
  firstStepInitialAnswers: {
    q1a: string;
    q1b: string;
    q1c: string;
    input: string;
  };
  initialState: any;
  placeholder?: string;
  isSubmitting?: boolean;
  value?: string;
  section?: string;
  isValid?: boolean;
  isFormPage?: boolean;
  setIsValid: (data: boolean) => void;
  step: string;
  handleStopGenerate: () => void;
  handleViewReport: () => void;
  sectionOutputPresent: boolean;
  isLoading: boolean;
  isAnswer?: boolean;
}

const DraftForm: React.FC<DraftFormProps> = ({
  Text,
  disabled,
  maxLength,
  handleSubmit,
  form,
  firstStepForm,
  initialState,
  placeholder,
  value,
  isSubmitting,
  section,
  isValid,
  isFormPage,
  setIsValid,
  step,
  handleStopGenerate,
  handleViewReport,
  sectionOutputPresent,
  isLoading,
  firstStepInitialAnswers,
  isAnswer,
}) => {
  const { pathname } = useLocation();
  const [copied, setCopied] = useState(false);
  const type = pathname.split("/")[3];

  const [count, setCount] = useState({
    characters: +initialState?.input?.length || 0,
    words: +initialState?.input?.trim().split(" ").length || 0,
  });

  const {
    user: { handleCheckInputTokensValid },
  } = useStore(null);

  const checkLengthOfInput = async (value: string | object) => {
    const data = {
      functionality: "concept note",
      section: section,
      inputs:
        +step === 1
          ? value
          : {
              input: value,
            },
    };

    const stringValue =
      typeof value === "string"
        ? value
        : Object.values(value).filter(Boolean).join("");

    setCount({
      characters: +stringValue.length,
      words: stringValue
        .trim()
        .split(" ")
        .filter((el) => !!el).length,
    });

    await handleCheckInputTokensValid(data).then((res) => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    if (initialState?.input && isFormPage)
      form.setFieldValue("q", initialState?.input);
  }, [initialState?.input]);

  useEffect(() => {
    if (!copied) return;

    setTimeout(() => {
      setCopied(null);
    }, 2000);
  }, [copied]);

  let isRequired = false;

  if (+step !== 12 && !disabled) {
    isRequired = true;
  }

  return type === "form" && step === "1" ? (
    <DraftFirstStep
      isLoading={isLoading}
      checkLengthOfInput={checkLengthOfInput}
      firstStepForm={firstStepForm}
      firstStepInitialAnswers={firstStepInitialAnswers}
      sectionOutputPresent={sectionOutputPresent}
      handleViewReport={handleViewReport}
      handleSubmit={handleSubmit}
    />
  ) : (
    <>
      <div className={style.formWrapper}>
        <Form
          className={style.form}
          form={form}
          autoComplete="false"
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          layout="vertical"
          initialValues={{
            q: initialState?.input,
          }}
        >
          <div className={style.labelContainer}>
            <span className={style.label}>{Text}</span>
            {type === "results" ? null : (
              <span
                className={
                  isLoading === false && sectionOutputPresent
                    ? style.viewOutputButton
                    : style.viewOutputButtonDisabled
                }
                onClick={() =>
                  sectionOutputPresent ? handleViewReport() : null
                }
              >
                View Last Output
              </span>
            )}
          </div>

          <div className={style.textareaWrapper}>
            <Form.Item
              name={"q"}
              rules={[
                { required: isRequired, message: "This field is required" },
              ]}
            >
              {isSubmitting && !isAnswer ? (
                <>
                  <SpinnerGeneration />
                </>
              ) : (
                <CommonInput
                  placeholder={
                    isSubmitting
                      ? "If you don’t see any output below within 7 seconds, you may have exceeded the permitted word limit in your input. Please reduce your input - thank you!"
                      : placeholder || ""
                  }
                  value={isFormPage ? initialState?.input : ""}
                  defaultValue={!isFormPage && !isSubmitting ? value : ""}
                  disabled={disabled}
                  size={3}
                  // TODO: removed input limit until further notice
                  // maxlength={maxLength * 10}
                  // onChange={(e) => {
                  //   checkLengthOfInput(e.target.value);
                  // }}
                  inputType="textarea"
                  style={{ cursor: isLoading ? "wait" : "", minHeight: "55vh" }}
                  autoSizeCheck={{
                    minRows: isFormPage ? 19 : 2,
                    maxRows: 1111,
                  }}
                  className={classNames(style.textarea)}
                  isMarkdown={!isFormPage}
                />
              )}
            </Form.Item>
            {!isFormPage &&
              form.getFieldValue("q") &&
              (copied ? (
                <img src={Copied} alt="copy" />
              ) : (
                <img
                  src={Copy}
                  alt="copy"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      removeMd(form.getFieldValue("q")),
                    );
                    setCopied(true);
                  }}
                />
              ))}
          </div>
        </Form>
        {/*<div>Words: {count.words} / 1000</div>*/}
        {/*<div>Characters: {count.characters} / 1000</div>*/}
        {/*{!isValid && (*/}
        {/*  <div style={{ color: "red", fontSize: "14px" }}>*/}
        {/*    You have exceeded the maximum length for this field*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{pathname.includes("/form/") && (*/}
        {/*  <div style={{ paddingTop: "5px" }}>*/}
        {/*    The max word limit for this page is {maxLength} words*/}
        {/*  </div>*/}
        {/*)}*/}
        {!isFormPage && (
          <div
            style={{
              border: "1px solid #E0E0E0",
              position: "relative",
              top: "-16px",
              margin: "1px",
              padding: "10px 17px",
              display: "flex",
              justifyContent: "end",
              borderRadius: "0 0 5px 5px",
            }}
          >
            <div
              style={{
                height: "fit-content",
                cursor: !isSubmitting ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (isSubmitting) {
                  handleStopGenerate();
                }
              }}
            >
              <StopIcon color={!isSubmitting ? "#dddddd" : ""} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(DraftForm);
