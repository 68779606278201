import style from "./style.module.scss";

const Footer = () => {
  return (
    <div className={style.footerContainer}>
      <p
        className={style.footerPTag}
        style={{ maxWidth: "850px", margin: "0 auto", marginBottom: "10px" }}
      >
        © 2024 Janus Advisory Services. All rights reserved.
      </p>
      <p
        className={style.footerPTag}
        style={{ maxWidth: "850px", margin: "0 auto", marginBottom: "25px" }}
      >
        ver. 3.9.2
      </p>
    </div>
  );
};

export default Footer;
