import StopIcon from "@assets/icons/StopIcon";
import Copied from "@assets/icons/copied.svg";
import Close from "@assets/icons/cross.svg";
import Copy from "@assets/icons/copy.svg";
import UploadV2 from "@assets/icons/upload-2.svg";
import { CommonInput } from "@commonComponents/input";
import { useStore } from "@stores/root-store";
import { validateMessages } from "@utils/json-data";
import { Form, Upload } from "antd";
import classNames from "classnames";
import React, { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import style from "./style.module.scss";
import SpinnerGeneration from "../spinner-generating";
import ReportModal from "@components/modal/report-modal";
import removeMd from "remove-markdown";

function resizeImage(file, maxWidth, maxHeight, callback) {
  const img = new Image();
  const reader = new FileReader();

  reader.onload = function (event) {
    img.src = event.target.result as string;
  };

  img.onload = function () {
    let width = img.width;
    let height = img.height;

    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }

    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    canvas.toBlob(function (blob) {
      callback(blob);
    }, "image/jpeg");
  };

  reader.readAsDataURL(file);
}

const resizeImageAsync = (file, maxWidth, maxHeight): Promise<string> =>
  new Promise((resolve) => {
    resizeImage(file, maxHeight, maxHeight, (blob) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        resolve(e.target.result.toString());
      };

      reader.readAsDataURL(blob);
    });
  });

interface GradeFormProps {
  Text: JSX.Element;
  disabled: boolean;
  maxLength: number;
  handleSubmit: (saveOnly: boolean) => void;
  form: any;
  initialState: any;
  placeholder?: string;
  isSubmitting?: boolean;
  value?: string;
  section?: string;
  isValid?: boolean;
  isFormPage?: boolean;
  setIsValid: (data: boolean) => void;
  step: string;
  handleStopGenerate: () => void;
  handleViewReport: () => void;
  sectionOutputPresent: boolean;
  isLoading: boolean;
  base64Image?: string | null;
  setBase64Image?: (image: string) => void;
  allowUpload: boolean | undefined;
  isAnswer?: boolean;
}

const GradeForm: React.FC<GradeFormProps> = ({
  Text,
  disabled,
  maxLength,
  handleSubmit,
  form,
  initialState,
  placeholder,
  value,
  isSubmitting,
  section,
  isValid,
  isFormPage,
  setIsValid,
  step,
  handleStopGenerate,
  handleViewReport,
  sectionOutputPresent,
  isLoading,
  base64Image,
  setBase64Image,
  allowUpload,
  isAnswer,
}) => {
  const { pathname } = useLocation();
  const type = pathname.split("/")[3];
  const [copied, setCopied] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [count, setCount] = useState({
    characters: +initialState?.input?.length || 0,
    words: +initialState?.input?.trim().split(" ").length || 0,
  });

  const wordLimits = [1000, 1500, 1500, 300, 500, 500];

  const {
    user: { handleCheckInputTokensValid },
  } = useStore(null);

  const checkLengthOfInput = async (value: string) => {
    const data = {
      functionality: "concept note evaluator",
      section: section,
      input: value,
    };

    setCount({
      characters: +value.length,
      words: value
        .trim()
        .split(" ")
        .filter((el) => !!el).length,
    });

    await handleCheckInputTokensValid(data).then((res) => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    if (!copied) return;

    setTimeout(() => {
      setCopied(null);
    }, 2000);
  }, [copied]);

  useEffect(() => {
    async function resizeAndSetImage() {
      if (selectedFile) {
        try {
          const base64 = await resizeImageAsync(selectedFile, 1024, 768);
          setBase64Image(base64); // Store the base64 image in state
        } catch (error) {
          console.error("Error resizing image:", error);
        }
      } else {
        setBase64Image("");
      }
    }

    resizeAndSetImage();
  }, [selectedFile]); // The effect depends on `selectedFile`

  let isRequired = false;
  if (+step !== 12 && !disabled) {
    isRequired = true;
  }

  return (
    <>
      <div className={style.formWrapper}>
        <Form
          className={style.form}
          form={form}
          autoComplete="false"
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          layout="vertical"
          initialValues={{
            q: initialState?.input,
          }}
        >
          <div className={style.labelContainer}>
            <span className={style.label}>{Text}</span>
            {type === "results" ? null : (
              <span
                className={
                  isLoading === false && sectionOutputPresent
                    ? style.viewOutputButton
                    : style.viewOutputButtonDisabled
                }
                onClick={() =>
                  sectionOutputPresent ? handleViewReport() : null
                }
              >
                View Last Output
              </span>
            )}
          </div>
          <div className={style.textareaWrapper}>
            <Form.Item>
              {allowUpload && isFormPage && base64Image && (
                <div className={style.previewWrapper}>
                  <div>
                    <img src={base64Image} alt="preview" />
                    <div
                      className={style.clearFile}
                      onClick={() => {
                        setSelectedFile(null);
                      }}
                    >
                      <img src={Close} alt="Clear file" />
                    </div>
                  </div>
                </div>
              )}
              <Form.Item
                name={"q"}
                rules={[
                  { required: isRequired, message: "This field is required" },
                ]}
                noStyle
              >
                {isSubmitting && !isAnswer ? (
                  <SpinnerGeneration />
                ) : (
                  <CommonInput
                    placeholder={
                      isSubmitting
                        ? "If you don’t see any output below within 7 seconds, you may have exceeded the permitted word limit in your input. Please reduce your input - thank you!"
                        : placeholder || ""
                    }
                    value={isFormPage ? initialState?.input : "aa"}
                    defaultValue={!isFormPage && !isSubmitting ? value : ""}
                    disabled={disabled}
                    size={3}
                    // TODO: removed input limit until further notice
                    // maxlength={maxLength * 10}
                    // onChange={(e) => {
                    //   checkLengthOfInput(e.target.value);
                    // }}
                    inputType="textarea"
                    style={{
                      cursor: isLoading ? "wait" : "",
                      minHeight: "55vh",
                    }}
                    autoSizeCheck={{
                      minRows: isFormPage ? 19 : 2,
                      maxRows: 1111,
                    }}
                    className={classNames(style.textarea)}
                    isMarkdown={!isFormPage}
                  />
                )}
              </Form.Item>
            </Form.Item>
            <div>
              {!isFormPage &&
                form.getFieldValue("q") &&
                (copied ? (
                  <img src={Copied} alt="copy" />
                ) : (
                  <img
                    src={Copy}
                    alt="copy"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        removeMd(form.getFieldValue("q")),
                      );
                      setCopied(true);
                    }}
                  />
                ))}
            </div>
          </div>
        </Form>
        {/*<div>Words: {count.words} / 1000</div>*/}
        {/*<div>Characters: {count.characters} / 1000</div>*/}
        {allowUpload && isFormPage && (
          <div className={style.uploadButtonBlock}>
            <div className={style.uploadButtonWrapper}>
              <Upload
                accept="image/*"
                onChange={(info) => {
                  setSelectedFile(info.file.originFileObj);
                }}
                itemRender={() => null}
              >
                <img src={UploadV2} />
              </Upload>
            </div>
          </div>
        )}
        {/*{!isValid && (*/}
        {/*  <div style={{ color: "red", fontSize: "14px", marginTop: "19px" }}>*/}
        {/*    You have exceeded the maximum length for this field*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{pathname.includes("/form/") && (*/}
        {/*  <div style={{ paddingTop: "5px", marginTop: "19px" }}>*/}
        {/*    The max word limit for this page is {maxLength} words*/}
        {/*  </div>*/}
        {/*)}*/}

        {!isFormPage && (
          <div
            style={{
              border: "1px solid #E0E0E0",
              position: "relative",
              top: "-16px",
              margin: "1px",
              marginTop: "15px",
              padding: "10px 17px",
              display: "flex",
              justifyContent: "end",
              borderRadius: "0 0 5px 5px",
            }}
          >
            <div
              style={{
                height: "fit-content",
                cursor: !isSubmitting ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (isSubmitting) {
                  handleStopGenerate();
                }
              }}
            >
              <StopIcon color={!isSubmitting ? "#dddddd" : ""} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(GradeForm);
