import { observer } from "mobx-react";
import { memo } from "react";
import { Collapse } from "antd";
import style from "./style.module.scss";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
interface Props {}

interface Props {}
const Faqs: React.FC<Props> = observer(({ ...props }) => {
  const onChange = (key: string | string[]) => {};
  return (
    <div className={style.mainContainer}>
      <h2 style={{ fontSize: "36px", fontWeight: "500" }}>FAQs</h2>
      <div className={style.container}>
        <Collapse
          expandIcon={({ isActive }) =>
            isActive ? (
              <div className={style.plusIcon}>
                <MinusOutlined />
              </div>
            ) : (
              <div className={style.plusIcon}>
                <PlusOutlined />
              </div>
            )
          }
          defaultActiveKey={["1"]}
          onChange={onChange}
        >
          <Panel
            className={style.accordionTitle}
            header="Q: Who has access to my project information and documents?"
            key="1"
          >
            <p>
              A: Your projects are private and can only be accessed by you and
              those you explicitly share access with. We do not share your
              information with third parties without your consent.
            </p>
          </Panel>

          <Panel
            header="Q: What are OpenAI's Large Language Models (LLMs), and how are they used in this application?"
            key="2"
          >
            <p>
              A: Open AI's LLMs are advanced LLMs that processes and generates
              human-like text. In Climate Finance Co-pilot, they help in
              understanding and responding to user queries, providing
              information, or assisting in various tasks. The interaction is
              designed to be seamless and intuitive, enhancing your experience
              with sophisticated AI technology.
            </p>
          </Panel>
          <Panel
            header="Q: How is my data processed when I use this application?"
            key="3"
          >
            <p>
              A: When you input data into our application, it is temporarily
              transmitted to OpenAI's servers for processing. The model analyzes
              the input incorporated into Janus’ prompts to understand your
              request and generates an appropriate response.
            </p>
          </Panel>

          <Panel
            header="Q: Is my personal data safe when using this application?"
            key="4"
          >
            <p>
              A: We prioritize data security and privacy. While your data is
              processed by OpenAI's models, we adhere to strict protocols to
              protect your information. Personal data is not stored longer than
              necessary for processing your requests, and we ensure that all
              data transmission is encrypted and secure.
            </p>
          </Panel>

          <Panel
            header="Q: Can the data I input into the application be used by OpenAI or third parties?"
            key="5"
          >
            <p>
              A: No, the data you input into the application is not used by
              OpenAI or any third parties for their purposes. It is solely used
              to provide the service you request within our application. OpenAI
              has policies in place to prevent the use of your data beyond
              fulfilling your immediate request.
            </p>
          </Panel>

          <Panel
            header="Q: What measures are in place to ensure data privacy and compliance?"
            key="6"
          >
            <p>
              A: We are committed to maintaining high standards of data privacy
              and comply with relevant data protection regulations. We have
              implemented measures such as data anonymization and encryption to
              ensure your privacy is safeguarded. Additionally, our partnership
              with OpenAI includes strict adherence to data protection and
              privacy standards.
            </p>
          </Panel>
          <Panel
            header="Q: How can I be sure that my sensitive information is handled correctly?"
            key="7"
          >
            <p>
              A: We encourage users to avoid inputting highly sensitive personal
              information into the application. However, if it is necessary,
              rest assured that our system is designed to handle such data with
              the utmost care and security, in line with our stringent privacy
              policies and regulatory requirements.
            </p>
          </Panel>
          <h3>Training and Support</h3>
          <Panel
            header="Q: Do you offer training on how to use the Climate Finance Co-pilot effectively?"
            key="9"
          >
            <p>
              A: Yes, we provide online tutorials, webinars, and access to
              customer support for training on how to utilize all the features
              of the Climate Finance Co-pilot to their full potential.
            </p>
          </Panel>

          <Panel
            header="Q: Where can I find the explainer videos for this application?"
            key="10"
          >
            <p>
              A: Our short explainer videos are available on our official
              YouTube channel. You can find a variety of videos there that
              demonstrate how to use our application, its features, and tips for
              getting the most out of it. Simply search for Janus Advisory
              Services on YouTube, or follow the link provided on our website.
            </p>
          </Panel>

          <Panel
            header="Q: What topics do the explainer videos cover?"
            key="11"
          >
            <p>
              A: The explainer videos cover a range of topics including an
              introduction to our application, step-by-step guides on using
              specific tools. We aim to make these videos comprehensive and easy
              to understand.
            </p>
          </Panel>

          <Panel
            header="Q:  How often are new explainer videos released?"
            key="12"
          >
            <p>
              A: We regularly update our YouTube channel with new explainer
              videos, especially when we roll out new features or significant
              updates to the application. We recommend subscribing to our
              YouTube channel and turning on notifications to stay updated with
              the latest content.
            </p>
          </Panel>

          <Panel
            header="Q: Can I request a topic for a future explainer video?"
            key="13"
          >
            <p>
              A: Absolutely! We value customer feedback and suggestions. If
              there's a specific topic or feature you'd like us to cover in our
              explainer videos, please let us know through our customer support
              or social media channels. We strive to create content that is most
              helpful to our users.
            </p>
          </Panel>

          <Panel
            header="Q: Where can I get help if I have questions while using the application?"
            key="14"
          >
            <p>
              A: Our customer support is available to assist you. You can reach
              out via the help desk, email, or through the live chat feature
              within the application.
            </p>
          </Panel>
          <h3>Updates and Feature Releases</h3>
          <Panel
            header="Q: How often is the Climate Finance Co-pilot updated with new features?"
            key="15"
          >
            <p>
              A: We regularly update the software with new features and
              improvements. Subscribers receive update notifications and have
              immediate access to the latest features.
            </p>
          </Panel>

          <Panel
            header="Q: Will I automatically receive updates to the Climate Finance Co-pilot?"
            key="16"
          >
            <p>
              A: Yes, all updates are automatically applied to your account,
              ensuring you always have access to the latest version without any
              action required on your part.
            </p>
          </Panel>

          <h3>Using the tools</h3>
          <Panel
            header="Q: How should I interpret the evaluation reports?"
            key="17"
          >
            <p>
              A: The evaluation reports provide an in-dept assessment of the
              quality of your project/programme from the perspective of the GCF
              evaluators. For the proposals, the evaluations are based on the
              same Investment Criteria Scorecard (ICS) used by the GCF. For
              Concept Notes, the evaluations are based on the criteria discuses
              in carious GCF manuals and templates.
            </p>
          </Panel>
          <Panel
            header="Q: How do I access the evaluation report for my project?"
            key="18"
          >
            <p>
              A: Once your project has been fully evaluated (i.e. your
              narratives evaluated against all criteria), you can access a
              detailed report listing the evaluation report in the Projects
              Library. This report can be used for your reference or shared with
              other project stakeholders.
            </p>
          </Panel>
          <Panel
            header="Q: Can I trust the AI-generated content and evaluation?"
            key="19"
          >
            <p>
              A: The CFC’s evaluation feature is based on advanced algorithms
              which have the GCF’s scoring rubric “baked” into them. As such,
              the evaluation reports provide the most objectivee evaluation of
              your input possible.
            </p>
          </Panel>

          <Panel
            header="Q: Are Concept Notes and Proposals (Full and SAP) evaluations using the same grading criteria/rubric?"
            key="20"
          >
            <p>
              A: Yes and no. Concept Note serves as a preliminary version of the
              Full Proposal and, therefore, should include narratives that align
              with the GCF's Investment Criteria Scorecard (ICS). However, it's
              important to note that at the Concept Note stage, these narratives
              will be more concise compared to the Full Proposal. As a result,
              CFC will not assess the Concept Note in the same manner as it
              evaluates a full proposal. Generating Reports
            </p>
          </Panel>

          <Panel
            header="Q: Can I export my completed evaluations, Concept Notes and Proposals?"
            key="21"
          >
            <p>
              A: Yes, you can export your completed Concept Notes and Proposals
              into a PDF file. This allows you to easily share your work with
              colleagues, submit it to funding entities and other stakeholders,
              or use it for further analysis and reporting purposes.
            </p>
          </Panel>
          <h3>Concept Note and Proposal Generation</h3>
          <Panel
            header="Q: Can the Climate Finance Co-pilot help me start a concept note from scratch?"
            key="22"
          >
            <p>
              A: Yes, the Concept Note Generator tool is designed to help you
              refine a concept note from the ground up by guiding you through
              the necessary sections and providing suggestions based on best
              practices and GCF guidelines.
            </p>
          </Panel>
          <Panel
            header="Q: What information do I need to provide to generate a concept note?"
            key="23"
          >
            <p>
              A: You should be ready to provide basic project information such
              as objectives, targeted outcomes, stakeholders, and preliminary
              financial structures. The more detailed your input, the more
              tailored the Concept Note Generator's output will be.
            </p>
          </Panel>
          <h3>Theory of Change and Project Structuring</h3>
          <Panel header="Q: What is the Theory of Change Advisor?" key="24">
            <p>
              A: The Theory of Change Advisor is a tool that helps you
              articulate the logical sequence of an initiative—from inputs to
              activities, outputs, outcomes, and ultimate impact. It ensures
              your proposal aligns with strategic goals and objectives.
            </p>
          </Panel>
          <Panel
            header="Q: How does the Project Structuring tool work?"
            key="25"
          >
            <p>
              A: This tool assists you in outlining the framework of your
              project, including governance, financial planning, risk
              management, and monitoring strategies. It prompts you to address
              key aspects that are critical for a successful proposal.
            </p>
          </Panel>
          <h3>Playground and Interactive Features</h3>
          <Panel header="Q: How does the Playground feature work? " key="26">
            <p>
              A: The CFC Playground allows you to interact directly with the AI
              in a free-form manner. You can input questions, experiment with
              different prompts, and see how the AI responds in real-time,
              aiding in creative brainstorming and problem-solving.
            </p>
          </Panel>
          <Panel
            header="Q: Can I use the Playground for tasks other than proposal writing? "
            key="27"
          >
            <p>
              A: Absolutely. The Playground is a versatile tool that can assist
              with a wide range of tasks, including research, generating ideas
              for project activities, and even learning more about climate
              finance terminologies and best practices.
            </p>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
});

export default memo(Faqs);
