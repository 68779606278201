import React from "react";
import { Col } from "antd";
import { observer } from "mobx-react";
import style from "./style.module.scss";
import { useUpgradePlanModal } from "@components/ModalContext/ModalContext";

export interface itemPropsTypes {
  item: any;
  cardGrid?: any;
}

const CardItemsFuncImg = observer(({ cardGrid, item }: itemPropsTypes) => {
  const { openModal } = useUpgradePlanModal();

  return (
    <Col
      lg={cardGrid ? 12 : 8}
      md={12}
      xs={24}
      style={{ maxWidth: "350px", padding: "0 13px 3px" }}
    >
      <div
        onClick={item.navigate}
        className={item.disable ? style.disableCards : style.cardItem}
      >
        {item?.status === "Upgrade" ? (
          <div className={style.statusBadgeNew} onClick={() => openModal()}>
            {item?.status}
          </div>
        ) : item?.status ? (
          <p className={style.statusBadge}> {item?.status || " "}</p>
        ) : null}
        <div
          style={{
            display: "flex",
            marginTop: "0",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={item?.img}
            style={{ opacity: item?.opacity, height: 81, width: "auto" }}
            alt=""
          />
        </div>
        <h4
          className={!item?.status?.length && style.withOutStatus}
          style={{ textAlign: "center" }}
        >
          {item?.title}
        </h4>
      </div>
    </Col>
  );
});

export default CardItemsFuncImg;
