import Faqs from "@components/layout/main-layout/private-layout/faqs";
import { Pricing } from "@components/layout/main-layout/private-layout/pricing";
import ForgotPassword from "@components/layout/main-layout/public-layout/forgot-password";
import CheckEmail from "@components/layout/main-layout/public-layout/forgot-password/check-email";
import ResetPasswordSuccessfully from "@components/layout/main-layout/public-layout/forgot-password/reset-password-successfully";
import SetNewPassword from "@components/layout/main-layout/public-layout/forgot-password/set-new-password";
import Login from "@components/layout/main-layout/public-layout/login";
import PrivacyPolicy from "@components/layout/main-layout/public-layout/privacy-policy";
import ProjectResults from "@components/layout/main-layout/public-layout/project-result/ProjectResults";
import Signup from "@components/layout/main-layout/public-layout/signup";
import VerifyEmail from "@components/layout/main-layout/public-layout/signup/verify-email";
import SoftwareAttribution from "@components/layout/main-layout/public-layout/software-attribution";
import TermOfUse from "@components/layout/main-layout/public-layout/term-of-use";
import StartingPage from "@components/layout/main-layout/public-layout/welcome-screen";
import ExistingProject from "@components/pages/ExistingProject/existingProject";
import SuperAdminLogin from "@components/pages/SuperAdmin/SuperAdminLogin";
import Home from "@components/pages/home";
import Playground from "@components/pages/project/Playground/Playground";
import AdvisorPage from "@components/pages/project/advisor-page";
import { DraftPage } from "@components/pages/project/draft-page";
import GradePage from "@components/pages/project/grade-page";
import ProjectStructureWelcome from "@components/pages/project/project-structure-welcome/ProjectStructureWelcome";
import RationalAdvisorPage from "@components/pages/project/rational-advisor-page";
import RationalAdvisorWelcome from "@components/pages/project/rational-advisor-welcome/RationalAdvisorWelcome";
import SelectDonor from "@components/pages/project/select-donor";
import SelectFunction from "@components/pages/project/select-function";
import WhichToDo from "@components/pages/project/select-function/which-to-do";
import SelectOne from "@components/pages/project/select-function/which-to-do/select-one";
import ProjectName from "@components/pages/project/select-function/which-to-do/select-one/project-name";
import GCFCongratulation from "@components/common-components/gcf-congratulation";
import SelectFunder from "@components/pages/project/select-funder";
import TheoryOfChangePage from "@components/pages/project/theory-of-change-page";
import TheoryOfChangeWelcome from "@components/pages/project/theory-of-change-welcome/TheoryOfChangeWelcome";
import { constRoute } from "@utils/route";
import { memo } from "react";
import { Route, Routes } from "react-router-dom";
import NoObjectionProcedurePage from "@components/pages/project/no-objection-procedure-page";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route
          path={constRoute?.draftProject + "/:type/:step"}
          element={<DraftPage />}
        />
        <Route
          path={constRoute?.gradeProject + "/:type/:step"}
          element={<GradePage />}
        />
        <Route
          path={constRoute?.advisorProject + "/:type/:step"}
          element={<AdvisorPage />}
        />
        <Route
          path={constRoute?.rationalAdvisorProject + "/:type/:step"}
          element={<RationalAdvisorPage />}
        />
        <Route
          path={constRoute?.theoryOfChangeProject + "/:type/:step"}
          element={<TheoryOfChangePage />}
        />
        <Route
          path={constRoute?.noObjectionProcedureProject + "/:type/:step"}
          element={<NoObjectionProcedurePage />}
        />
        <Route path={constRoute?.projectResult} element={<ProjectResults />} />
        <Route path={constRoute?.dashboard} element={<StartingPage />} />
        <Route path={constRoute?.login} element={<Login />} />
        <Route path={constRoute?.signup} element={<Signup />} />
        <Route path={constRoute?.forgetPassword} element={<ForgotPassword />} />
        <Route path={constRoute?.checkEmail} element={<CheckEmail />} />
        <Route path={constRoute?.setNewPassword} element={<SetNewPassword />} />
        <Route
          path={constRoute?.resetPasswordSuccessfully}
          element={<ResetPasswordSuccessfully />}
        />
        <Route path={constRoute?.verifyEmail} element={<VerifyEmail />} />
        <Route path={constRoute?.home} element={<Home />} />
        <Route path={constRoute?.faqs} element={<Faqs />} />
        <Route
          path={constRoute?.playground + "/:type/:step"}
          element={<Playground />}
        />
        <Route path={constRoute?.selectFunder} element={<SelectFunder />} />
        <Route path={constRoute?.selectDonor} element={<SelectDonor />} />
        <Route path={constRoute?.selectFunction} element={<SelectFunction />} />
        <Route
          path={constRoute?.projectStructureWelcome}
          element={<ProjectStructureWelcome />}
        />
        <Route
          path={constRoute?.theoryOfChangeWelcome}
          element={<TheoryOfChangeWelcome />}
        />
        <Route
          path={constRoute?.rationalAdvisorWelcome}
          element={<RationalAdvisorWelcome />}
        />
        <Route
          path={constRoute?.masteradminLogin}
          element={<SuperAdminLogin />}
        />
        <Route
          path={constRoute?.whichToDo + "/:type"}
          element={<WhichToDo />}
        />
        <Route path={constRoute?.selectOne} element={<SelectOne />} />
        <Route
          path={constRoute?.projectName + "/:type"}
          element={<ProjectName />}
        />

        <Route
          path={constRoute?.gcfCongratulation + "/:type"}
          element={<GCFCongratulation />}
        />
        <Route
          path={constRoute?.existingProject}
          element={<ExistingProject />}
        />
        <Route path={constRoute?.TermOfUse} element={<TermOfUse />} />
        <Route path={constRoute?.setting} element={<PrivacyPolicy />} />
        <Route
          path={constRoute?.softwareAttribution}
          element={<SoftwareAttribution />}
        />
        <Route path={constRoute?.pricing} element={<Pricing />} />
      </Routes>
    </>
  );
};
export default memo(Routing);
