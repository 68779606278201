export const constRoute = {
  hash: "#",
  draftProject: "/project/draft",
  gradeProject: "/project/evaluate",
  advisorProject: "/project/advisor",
  theoryOfChangeProject: "/project/theory-of-change",
  noObjectionProcedureProject: "/project/no-objection-procedure",
  rationalAdvisorProject: "/project/rational-advisor",
  dashboard: "/",
  home: "/home",
  login: "/login",
  faqs: "/faqs",
  playground: "/playground",
  TermOfUse: "/term-of-use",
  projectResult: "/project-result",
  softwareAttribution: "software-attribution",
  admin: "/admin",
  adminDashboard: "/admin/dashboard",
  adminUsers: "/admin/users",
  adminBilling: "/admin/billing",
  masteradminDashboard: "/masteradmin/dashboard",
  masteradminUsers: "/masteradmin/users",
  masteradminPromptPanel: "/masteradmin/prompt-panel",
  masteradminRecource: "/masteradmin/resource-center",
  masteradminBilling: "/masteradmin/billing",
  masteradminLogin: "/masteradmin",
  theoryOfChangeWelcome: "/theory-of-change-welcome",
  rationalAdvisorWelcome: "/rational-advisor-welcome",
  projectStructureWelcome: "/project-structure-welcome",
  gcfCongratulation: "/gcf-congratulation",
  signup: "/signup",
  forgetPassword: "/forget-password",
  checkEmail: "/check-email",
  setNewPassword: "/set-new-password",
  resetPasswordSuccessfully: "/reset-password-successfully",
  verifyEmail: "/verify-email",
  selectFunder: "/select-funder",
  selectFunction: "/select-function",
  selectDonor: "/select-donor",
  selectOne: "/select-one",
  whichToDo: "/which-to-do",
  projectName: "/project-name",
  finalSheet: "/final-sheet",
  profile: "/profile",
  setting: "/setting",
  existingProject: "/myProjects",
  pricing: "/pricing",
};
