import { useTokensContext } from "@components/layout/main-layout/private-layout";
import { useStore } from "@stores/root-store";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import tooltipIcon from "@assets/icons/question-tooltip.svg";
import Close from "@assets/icons/cross.svg";

import style from "./style.module.scss";
import { preferencesApi } from "@api";
import { notification } from "antd";

export interface HeaderTypes {
  projectName?: string;
  percent?: string;
  conceptNoteSection?: string;
  ribonBg?: any;
  documentTypeStyle?: any;
  documentTypeSubHeading?: any;
}

const HeaderPercentageBanner = ({
  projectName = "Your Project Name",
  percent = "--%",
  conceptNoteSection = "Your Concept Note Section",
  ...props
}: HeaderTypes) => {
  const isEvaluatePage = useLocation().pathname.includes("evaluate");
  const isAdvisorPage = useLocation().pathname.includes("/advisor/");
  const isRationalPage = useLocation().pathname.includes("/rational-advisor/");
  const { pathname } = useLocation();
  const [tokens, setTokens] = useState("");
  const { tokens: tokensFromContext } = useTokensContext();
  const [isShowTooltip, setIsShowTooltip] = useState(false);

  const [defaultTool, setDefaultTool] = useState(
    localStorage.getItem("defaultTool"),
  );

  const {
    user: { getProjectNameData, projectData },
  } = useStore(null);

  const getProjectName = localStorage.getItem("projectName");
  const projectNameData = pathname.includes("/playground/")
    ? "N/A"
    : JSON.parse(getProjectNameData)?.project_name ||
      getProjectName ||
      projectName;

  const isPlayground = pathname.includes("/playground/");

  let content;

  switch (true) {
    case pathname.includes("/playground/"):
      content = "Playground";
      break;
    case pathname.includes("/theory-of-change/"):
      content = "Theory of Change";
      break;
    case pathname.includes("/no-objection-procedure/"):
      content = "No Objection Procedure";
      break;
    case isEvaluatePage:
      content = "Evaluate a Concept Note";
      break;
    case isAdvisorPage:
      content = "Project Structuring";
      break;
    case isRationalPage:
      content = "Climate Rationale Advisor";
      break;
    case pathname.includes("/draft/"):
      content = "Refine a Concept Note";
      break;
    default:
      content = null;
  }

  const handleSetDefaultTool = async (tool) => {
    const toolLowerCase = tool.toLowerCase();
    const res = await preferencesApi.setDefaultTool(toolLowerCase);

    if ((res.message = "Default tool saved successfully")) {
      localStorage.setItem("defaultTool", toolLowerCase);
      notification.success({
        placement: "topRight",
        message: `${tool} is now your default`,
      });
    } else {
      notification.error({
        placement: "topRight",
        message: `Failed to set ${tool} your default`,
      });
    }

    setDefaultTool(localStorage.getItem("defaultTool"));
  };

  if (isPlayground) {
    return (
      <div
        className={style.contextBaselineDiv}
        style={{
          backgroundColor: props?.ribonBg,
          gridTemplateColumns: "1fr 3fr 1fr",
        }}
      >
        <div style={{ minWidth: "220px" }} className="header-item">
          <p style={{ ...props?.documentTypeStyle }} className={style.pTageOne}>
            Tool at work:
          </p>
          <span
            style={{
              width: "150%",
              display: "flex",
              whiteSpace: "nowrap",
              overflow: "hidden",
              flexWrap: "wrap",
              wordBreak: "break-all",
              color: "#FFFFFF",
            }}
          >
            {content}
          </span>
        </div>
        <div
          style={{ minWidth: "220px" }}
          className={`header-item ${style.defaultToolBannerText}`}
        >
          {defaultTool ? (
            <>
              {defaultTool === "playground"
                ? "Make home your default again?"
                : "Make playground your default again?"}
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
                onClick={() =>
                  handleSetDefaultTool(
                    defaultTool === "playground" ? "Home" : "Playground",
                  )
                }
              >
                Yes, make it default
              </span>
            </>
          ) : (
            <>Welcome to Climate Finance Playground!</>
          )}
        </div>
        <div style={{ position: "relative" }} className="header-item">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <p
                style={{ ...props?.documentTypeStyle }}
                className={style.pTageThree}
              >
                Tokens used / Tokens purchased:
              </p>
              <span
                style={{
                  color: props?.documentTypeSubHeading
                    ? props?.documentTypeSubHeading
                    : "#FFFFFF",
                  display: "flex",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  flexWrap: "wrap",
                  wordBreak: "break-all",
                }}
                title={
                  conceptNoteSection?.length > 48 ? conceptNoteSection : ""
                }
              >
                {tokens || tokensFromContext}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                src={tooltipIcon}
                alt="tooltipIcon"
                style={{
                  position: "relative",
                  left: "0",
                  top: "-12px",
                }}
                onMouseEnter={() => setIsShowTooltip(true)}
                onMouseLeave={() => setIsShowTooltip(false)}
              />
              {isShowTooltip && (
                <div
                  className={style.tooltipWrap}
                  // Overriding default tooltip
                  style={{
                    right: "40px",
                    top: "-77px",
                    zIndex: "10",
                  }}
                  onMouseEnter={() => setIsShowTooltip(true)}
                  onMouseLeave={() => setIsShowTooltip(false)}
                >
                  <div
                    className={style.tooltipArrow}
                    // Overriding default tooltip
                    style={{
                      bottom: "50%",
                      right: "13px",
                      top: "unset",
                      left: "unset",
                      zIndex: "10",
                    }}
                  ></div>
                  <div className={style.tooltip}>
                    <div className={style.tooltipTitle}>Important!</div>
                    <div className={style.tooltipDesc}>
                      "Tokens" are the AI version of your cell phone carrier's
                      "data". The number of tokens you purchased is your data
                      limit. Your billing cycle is a monthly cycle that begins
                      on your payment date.
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ backgroundColor: props?.ribonBg }}
      className={style.contextBaselineDiv}
    >
      <div style={{ minWidth: "220px" }} className="header-item">
        <p style={{ ...props?.documentTypeStyle }} className={style.pTageOne}>
          Tool at work:
        </p>
        <span
          style={{
            width: "150%",
            display: "flex",
            whiteSpace: "nowrap",
            overflow: "hidden",
            flexWrap: "wrap",
            wordBreak: "break-all",
            color: "#FFFFFF",
          }}
        >
          {content}
        </span>
      </div>
      <div className="header-item">
        <p style={{ ...props?.documentTypeStyle }} className={style.pTageOne}>
          Project name:
        </p>
        <span
          style={{
            display: "flex",
            whiteSpace: "nowrap",
            overflow: "hidden",
            flexWrap: "wrap",
            wordBreak: "break-all",
            color: props?.documentTypeSubHeading || "#FFFFFF",
          }}
        >
          {projectNameData}
        </span>
        {/* <img src={ProjectName} alt="icon" /> */}
      </div>
      <div style={{ position: "relative" }} className="header-item">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <p
              style={{ ...props?.documentTypeStyle }}
              className={style.pTageThree}
            >
              Tokens used / Tokens purchased:
            </p>
            <span
              style={{
                color: props?.documentTypeSubHeading
                  ? props?.documentTypeSubHeading
                  : "#FFFFFF",
                display: "flex",
                whiteSpace: "nowrap",
                overflow: "hidden",
                flexWrap: "wrap",
                wordBreak: "break-all",
              }}
              title={conceptNoteSection?.length > 48 ? conceptNoteSection : ""}
            >
              {tokens || tokensFromContext}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img
              src={tooltipIcon}
              alt="tooltipIcon"
              style={{
                position: "relative",
                left: "0",
                top: "-12px",
              }}
              onMouseEnter={() => setIsShowTooltip(true)}
              onMouseLeave={() => setIsShowTooltip(false)}
            />
            {isShowTooltip && (
              <div
                className={style.tooltipWrap}
                onMouseEnter={() => setIsShowTooltip(true)}
                onMouseLeave={() => setIsShowTooltip(false)}
              >
                <div className={style.tooltipArrow}></div>
                <div className={style.tooltip}>
                  <div className={style.tooltipTitle}>Important!</div>
                  <div className={style.tooltipDesc}>
                    "Tokens" are the AI version of your cell phone carrier's
                    "data". The number of tokens you purchased is your data
                    limit. Your billing cycle is a monthly cycle that begins on
                    your payment date.
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <img src={NoteIcon} alt="icon" /> */}
      </div>
      <div className="header-item">
        <p style={props?.documentTypeStyle} className={style.pTageTwo}>
          Percent complete:
        </p>
        <span
          style={{
            color: props?.documentTypeSubHeading
              ? props?.documentTypeSubHeading
              : "#FFFFFF",
          }}
        >
          {percent}
        </span>
        {/* <img src={TimerIcon} alt="icon" /> */}
      </div>
    </div>
  );
};

export default HeaderPercentageBanner;
