import React, { useState } from "react";
import { useUpgradePlanModal } from "@components/ModalContext/ModalContext";
import styles from "./upgradePlanModal.module.scss";
import classNames from "classnames";
import { useStore } from "@stores/root-store";
import ContactSalesModal from "@commonComponents/contact-sales-modal/ContactSalesModal";

const UpgradePlanModal = () => {
  const { isModalOpen, closeModal } = useUpgradePlanModal();
  const [openContactModal, setOpenContactModal] = useState(false);

  const {
    user: { getUserType },
  } = useStore(null);

  const localUserType: "basic" | "premium" | "enterprise" = getUserType;

  if (!isModalOpen) return null;

  return (
    <div className={styles.modalBg}>
      <div className={styles.modal}>
        <div className={styles.modalCloseIcon} onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.4546 0.252083C10.1405 -0.0729166 9.63307 -0.0729166 9.31894 0.252083L5.38027 4.31875L1.4416 0.24375C1.12747 -0.08125 0.620035 -0.08125 0.305908 0.24375C-0.00821932 0.56875 -0.00821932 1.09375 0.305908 1.41875L4.24458 5.49375L0.305908 9.56875C-0.00821932 9.89375 -0.00821932 10.4187 0.305908 10.7437C0.620035 11.0687 1.12747 11.0687 1.4416 10.7437L5.38027 6.66875L9.31894 10.7437C9.63307 11.0687 10.1405 11.0687 10.4546 10.7437C10.7688 10.4187 10.7688 9.89375 10.4546 9.56875L6.51596 5.49375L10.4546 1.41875C10.7607 1.10208 10.7607 0.56875 10.4546 0.252083Z"
              fill="#2E2E2E"
            />
          </svg>
        </div>

        <div className={styles.modalContentWrap}>
          <div className={styles.modalContentItemHead}>
            <p
              className={styles.modalContentItemHeadTitle}
              style={{ marginBottom: "20px" }}
            >
              Choose your plan
            </p>
            <p className={styles.modalContentItemHeadDesc}>
              Service plans are designed to assist all stakeholders in advancing
              GCF projects. Select the plan that best aligns with your
              requirements.
            </p>
          </div>
          <div className={styles.modalContentItemHead}>
            <div className={styles.modalContentItemHeadTitleWrap}>
              <p className={styles.modalContentItemHeadTitle}>Basic</p>
            </div>
            <p className={styles.modalContentItemHeadDesc}>
              For Project Owners who are at, or approaching, the Concept Note
              phase of the project lifecycle.
            </p>
            <p className={styles.modalContentItemHeadPrice}>Free</p>
            <div
              className={classNames(
                styles.modalContentItemHeadButton,
                localUserType === "basic" &&
                  styles.modalContentItemHeadButtonDisabled,
                localUserType === "premium" &&
                  styles.modalContentItemHeadButtonActive,
              )}
            >
              {localUserType === "basic"
                ? "Your Current Plan"
                : "Contact Sales"}
            </div>
          </div>

          <div className={styles.modalContentItemHead}>
            <div className={styles.modalContentItemHeadTitleWrap}>
              <p className={styles.modalContentItemHeadTitle}>Premium</p>
              <p className={styles.modalContentItemHeadTitleButton}>
                Best value
              </p>
            </div>

            <p className={styles.modalContentItemHeadDesc}>
              For Project Owners seeking support in structuring projects and
              drafting or assessing Concept Notes and Proposals.
            </p>
            {/*<p className={styles.modalContentItemHeadPrice}>$499.99/mo.</p>*/}
            <div
              className={classNames(
                styles.modalContentItemHeadButton,
                localUserType === "premium" &&
                  styles.modalContentItemHeadButtonDisabled,
                localUserType === "basic" &&
                  styles.modalContentItemHeadButtonActive,
              )}
              onClick={() => setOpenContactModal(true)}
            >
              {localUserType === "premium"
                ? "Your Current Plan"
                : "Contact Sales"}
            </div>
          </div>

          <div
            className={styles.modalContentItemHead}
            style={{ borderRight: "0" }}
          >
            <div className={styles.modalContentItemHeadTitleWrap}>
              <p className={styles.modalContentItemHeadTitle}>Enterprise</p>
            </div>
            <p className={styles.modalContentItemHeadDesc}>
              A fully customized solution for institutions. Offers own admin
              panel, database and enhanced privacy and security configurations.
            </p>
            {/*<p className={styles.modalContentItemHeadPrice}>$999.99/mo.</p>*/}
            <div
              className={classNames(
                styles.modalContentItemHeadButton,
                localUserType === "enterprise" &&
                  styles.modalContentItemHeadButtonDisabled,
                localUserType === "basic" ||
                  (localUserType === "premium" &&
                    styles.modalContentItemHeadButtonActive),
              )}
              onClick={() => setOpenContactModal(true)}
            >
              {localUserType === "enterprise"
                ? "Your Current Plan"
                : "Contact Sales"}{" "}
            </div>
          </div>

          <div className={styles.modalContentItemHeadRow}>Number of Users</div>
          <div className={styles.modalContentItem}>1</div>
          <div className={styles.modalContentItem}>Up to 3</div>
          <div className={styles.modalContentItem} style={{ borderRight: "0" }}>
            Up to 6
          </div>

          <div className={styles.modalContentItemHeadRow}>Functions</div>
          <div className={styles.modalContentItem}>Evaluate a Concept Note</div>
          <div className={styles.modalContentItem}>
            All Basic features plus: Refine a Concept Note, Evaluate a Readiness
            Proposal and Renewable Energy Project Structuring
          </div>
          <div className={styles.modalContentItem} style={{ borderRight: "0" }}>
            All Premium features plus: Climate Rationale Advisor and National
            Alignment Evaluation
          </div>

          <div className={styles.modalContentItemHeadRow}>Model</div>
          <div className={styles.modalContentItem}>GPT-3.5</div>
          <div className={styles.modalContentItem}>Hybrid</div>
          <div className={styles.modalContentItem} style={{ borderRight: "0" }}>
            Hybrid
          </div>

          <div
            className={classNames(
              styles.modalContentItemHeadRow,
              styles.modalContentItemHeadRowAsterisk,
            )}
          >
            Data Limit
          </div>
          <div className={styles.modalContentItem}>Limited</div>
          <div className={styles.modalContentItem}>Unlimited</div>
          <div className={styles.modalContentItem} style={{ borderRight: "0" }}>
            Unlimited
          </div>

          <div className={styles.modalContentItemHeadRow}>
            Extra User Cost/Month
          </div>
          <div className={styles.modalContentItem}>N/A</div>
          <div className={styles.modalContentItem}>$150</div>
          <div className={styles.modalContentItem} style={{ borderRight: "0" }}>
            $150
          </div>

          <div className={styles.modalContentItemHeadRow}>
            Additional Services
          </div>
          <div className={styles.modalContentItem}>N/A</div>
          <div className={styles.modalContentItem}>N/A</div>
          <div className={styles.modalContentItem} style={{ borderRight: "0" }}>
            Training Modules and Webinars, Access to Prompt Library
          </div>
        </div>

        <div className={styles.modalFooter}>
          <p className={styles.modalFooterItem}>
            Like a cellular network, the currency of AI Large Language Models
            (LLM) is data. Rates are based on capability of the AI model being
            used. The more capable and advanced the model, the higher the rates.
          </p>
        </div>
      </div>
      <ContactSalesModal
        isOpen={openContactModal}
        closeModal={() => setOpenContactModal(false)}
        setIsOpen={setOpenContactModal}
      />
    </div>
  );
};

export default UpgradePlanModal;
