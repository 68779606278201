import EmailIcon from "@assets/icons/EmailIcon";
import printButton from "@assets/icons/printButton.svg";
import exportIcon from "@assets/images/export.svg";
import sendMailIcon from "@assets/images/sendMailIcon.svg";
import style from "@commonComponents/gcf-congratulation/style.module.scss";
import { Form } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const ModalHeader = ({
  onExportToWord = null,
  onExportToPdf,
  handlePrint,
  handleSendResultToEmail,
  sendEmailState,
  topLine = false,
}) => {
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false);
  const [isOpenSendInput, setIsOpenSendInput] = useState(false);
  const [email, setEmail] = useState("");

  const { pathname } = useLocation();

  const pageType = pathname.includes("/no-objection-procedure")
    ? "no-objection-procedure"
    : pathname.includes("/advisor")
      ? "advisor"
      : pathname.includes("evaluate")
        ? "evaluate"
        : pathname.includes("/theory-of-change")
          ? "theory-of-change"
          : pathname.includes("/rational-advisor")
            ? "rational-advisor"
            : "draft";

  const title = (() => {
    switch (pageType) {
      case "advisor":
        return "Project Structuring Report:";
      case "evaluate":
        return "Evaluate a Concept Note Report:";
      case "draft":
        return "Refine a Concept Note Report:";
      case "theory-of-change":
        return "Theory of Change Report:";
      case "no-objection-procedure":
        return "No Objection Procedure Report:";
      case "rational-advisor":
        return "Climate Rationale Advisor Report:";
      default:
        return "Concept Narratives Generated Draft:";
    }
  })();

  useEffect(() => {
    setIsLoadingSendEmail(false);
  }, [sendEmailState]);

  return (
    <>
      {topLine && <div className={style.topLine} />}
      <h1
        style={{
          letterSpacing: topLine ? "-3%" : "",
          fontSize: topLine ? "18px" : "14px",
        }}
      >
        {title.split(" ").map((word, index) =>
          word.toLowerCase() === "report:" ? (
            <span key={index} style={{ fontWeight: "400" }}>
              {word}{" "}
            </span>
          ) : (
            <span key={index}>{word} </span>
          ),
        )}
      </h1>
      <div className={style.exportButtonsWrapper}>
        {/*<button className={style.exportToWordBtn} onClick={onExportToPdf}>*/}
        {/*  <img src={exportIcon} alt="" />*/}
        {/*  Export to pdf*/}
        {/*</button>*/}
        {!isOpenSendInput ? (
          <div
            onClick={() => {
              setIsOpenSendInput(true);
            }}
            className={style.exportButtonsImg}
          >
            <EmailIcon />
            <p>
              {isLoadingSendEmail
                ? "loading..."
                : sendEmailState || "Email a copy"}
            </p>
          </div>
        ) : (
          <Form className={style.enterEmail}>
            <Form.Item
              className={style.enterEmailWrap}
              style={{
                color: "#00b",
              }}
              name={"email"}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: (
                    <div style={{ fontSize: "12px", fontWeight: "400" }}>
                      Please Enter a valid Email
                    </div>
                  ),
                },
              ]}
            >
              <input
                type="email"
                name="email"
                className={style.emailInput}
                placeholder={"email@gmail.com"}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <img
              src={sendMailIcon}
              alt=""
              className={style.sendIcon}
              onClick={() => {
                const emailRegex =
                  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                if (email && emailRegex.test(email)) {
                  handleSendResultToEmail(email);
                  setIsOpenSendInput(false);
                }
              }}
            />
          </Form>
        )}
        <img
          src={printButton}
          alt="print"
          style={{ cursor: "pointer", marginRight: "40px" }}
          onClick={() => handlePrint()}
        />
        {onExportToWord && (
          <button className={style.exportToWordBtn} onClick={onExportToWord}>
            <img src={exportIcon} alt="" />
            Export to word
          </button>
        )}
        {/* {onExportToPdf && (
          <button className={style.exportToWordBtn} onClick={onExportToPdf}>
            <img src={exportIcon} alt="" />
            Export as pdf
          </button>
        )} */}
      </div>
    </>
  );
};
