import { observer } from "mobx-react";
import style from "./style.module.scss";
import { Row } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { constRoute } from "@utils/route";
import CardItemsFunc from "../../common-components/card-item-func";
import GoBack from "@components/common-components/go-back";
import { useStore } from "@stores/root-store";

const WhichToDo = observer(() => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { type } = useParams();

  const {
    user: { getUserType },
  } = useStore(null);

  const userType = getUserType;

  const functionEval = (() => {
    switch (userType) {
      case "basic":
        return "Evaluate a GCF Proposal";
      case "premium":
        return "Evaluate a Full Proposal";
      case "enterprise":
        return "Evaluate a Full Proposal";
      default:
        return "Evaluate a GCF Proposal";
    }
  })();

  let btnCardsList = [];
  switch (type) {
    case "evaluate":
      btnCardsList = [
        {
          title: "Evaluate a Concept Note",
          status: "",
          navigate: () => navigate(constRoute?.projectName + "/evaluate"),
        },
        {
          title: functionEval,
          status: userType === "basic" ? "Coming Soon" : "",
          disable: userType === "basic",
        },
      ];
      break;
    case "draft":
      btnCardsList = [
        {
          title: "Refine a Concept Note",
          status: "",
          navigate: () => navigate(constRoute?.projectName + "/draft"),
        },
        {
          title: "Refine a GCF Proposal",
          status: "Coming Soon",
          disable: true,
        },
      ];
      break;
    default:
      break;
  }
  return (
    <div>
      <div className={style.homePagePageContainer}>
        <Row>
          <div className={style.homePageContainerCol}>
            <div className={style.homePageContainer}>
              <h1>Which do you want to do?</h1>
              <Row justify="center" className={style.boxesContiner} gutter={20}>
                {btnCardsList?.map((item) => {
                  return <CardItemsFunc item={item} />;
                })}
              </Row>
            </div>
          </div>
        </Row>
      </div>
      <div className={style.btnDiv}>
        <div className={style.twoBtnDiv}>
          <div className={style.goBackBtnDiv}>
            <GoBack
              onClick={constRoute?.selectFunction}
              className={style.goBtn}
            />
          </div>
          {/* <button className={style.goBtn}  onClick={() =>
        navigate(constRoute?.selectFunction)
      }
>
      
        <img src={LeftArrow} alt="left-arrow" /> Go Back
      </button> */}
          {/* <button
              onClick={() => {
                notification.success("Save and Quit");
                navigate(constRoute?.home);
              }}
              className={style.saveBtn}
            >
              Save & Quit
            </button> */}
        </div>
      </div>
    </div>
  );
});

export default WhichToDo;
