import React, { FC, useState } from "react";
import { constImages } from "@utils/images";
import styles from "./style.module.scss";
import { Tooltip } from "antd";

type Props = {
  showAll: boolean;
  style: any;
};

const EmailReport: FC<Props> = ({ showAll, style }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [copied, setCopied] = useState(false);
  const email = "admin@janusadvisoryinc.com";

  const handleCopy = () => {
    navigator.clipboard.writeText(email);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Tooltip disappears after 2 seconds
  };

  return (
    <div
      className={style.emailBannerWrap}
      onMouseEnter={() => setShowPopup(true)}
      onMouseLeave={() => setShowPopup(false)}
    >
      <div
        className={style.emailBannerIconWrap}
        style={{ cursor: !showAll ? "pointer" : "" }}
      >
        <img
          style={{ width: "20px" }}
          src={constImages.questionMark}
          alt="mail"
        />
      </div>
      {showAll ? (
        <div>
          {/*<p className={style.emailHeadingText}>Questions or Issues?</p>*/}
          <div className={style.emailText}>
            Support:{" "}
            <Tooltip
              title={copied ? "Copied!" : null}
              // title={copied ? "Copied!" : "Click to copy"}
              visible={copied || undefined}
            >
              <a
                style={{ textDecoration: "underline", color: "#2e2e2e" }}
                href={`mailto:${email}`}
                onClick={handleCopy}
              >
                {email}
              </a>
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className={styles.popup}>
          <div style={{ display: !showPopup ? "none" : "block" }}>
            {/*<p className={style.emailHeadingText}>Questions or Issues?</p>*/}
            <div className={style.emailText}>
              Support:{" "}
              <a
                style={{ textDecoration: "underline", color: "#2e2e2e" }}
                href="mailto:admin@janusadvisoryinc.com"
                onClick={handleCopy}
              >
                {email}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailReport;
